import React, { useState, useEffect } from "react";
import { IResourceComponentsProps, useCustom } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Col, Form, Input, Row } from "antd";
import { useSelector } from "react-redux";
import RouterProvider from "@refinedev/react-router-v6/legacy";

export const EditCategory: React.FC<IResourceComponentsProps> = () => {
  const { categories } = useSelector((state: any) => ({
    ...state,
  }));
  const [image, setImage] = useState<any>({});
  const [imageName, setImageName] = useState<any>("");
  const [name, setName] = useState<any>("");
  const [id, setId] = useState<any>(0);
  const history = RouterProvider.useHistory();

  useEffect(() => {
    if (categories.selectedCategory !== undefined && categories.selectedCategory !== null) {
      setImageName(categories.selectedCategory.image);
      setName(categories.selectedCategory.name);
      // setId(categories.selectedCategory._id);
    }else{
      history.push("/")
    }
  }, []);

  // use use custom

  const { formProps, saveButtonProps } = useForm<any>({
    resource: "categories",
    id,
  });

  console.log("formProps", formProps);

  let formData = new FormData();
  formData.append("name", name);
  formData.append("image", image);

  const { data, refetch }: any = useCustom({
    url: `http://176.126.87.249/api/categories/${id}`,
    method: "post",
    config: {
      headers: {
        "Content-Type": "image/jpeg",
      },
      payload: formData,
    },
    queryOptions: {
      enabled: id.length > 0,
    },
  });

  return (
    <Row gutter={16} justify={"center"} style={{ marginTop: 20 }}>
      <Col xs={24} sm={24} md={22} lg={22} xl={22}>
        <Edit saveButtonProps={saveButtonProps} title={"Edit Category"}>
          <Form
            encType="multipart/form-data"
            {...formProps}
            style={{ margin: 15 }}
            layout="vertical"
            onFinish={(values: any) => {
              // let formData = new FormData();
              // formData.append("name", name);
              // formData.append("image", image);
              // return formProps.onFinish?.(formData);
              setId(categories.selectedCategory._id);
              console.log(categories.selectedCategory._id);
            }}
          >
            <Form.Item
              label={"Name"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item
              label={"Image"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <input
                type="file"
                onChange={(e: any) => {
                  setImage(e.target.files[0]);
                }}
              />
              <h3>{imageName}</h3>
            </Form.Item>
          </Form>
        </Edit>
      </Col>
    </Row>
  );
};
